<template>
    <div>
        <div class="title">
            <h2>Eventos</h2>
        </div>
        <!-- <ListLocais /> -->
        <HoraLocal :title="' '" />
        <Type @type="setType" :dados="type" />
        <ListEventos :params="type" />
    </div>
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.css'
import HoraLocal from '../components/HoraLocal'
import Type from '../components/Type'
import ListEventos from '../components/ListEventos.vue'
import { getAnalytics, logEvent } from 'firebase/analytics'

export default {
    components: { HoraLocal, Type, ListEventos },
    data: () => ({
        type: []
        //type: ['comunitario', 'raid', 'horaRaid', 'horaDestaque', 'liga', 'geral']
    }),
    methods: {
        setType(type) {
            const index = this.type.findIndex(i => i === type)
            if (index > -1) {
                this.type.splice(index, 1)
            } else {
                this.type.push(type)
            }
        }
    },
    mounted() {
        const analytics = getAnalytics(this.$fire)
        logEvent(analytics, 'screen_view', {
            firebase_screen: `APP - ${this.$route.meta.lang} ${this.$route.meta.titulo}`,
            firebase_screen_class: 'Eventos'
        })
        document.title = `${this.$route.meta.lang} CoordsGo.com - ${this.$route.meta.titulo}`
    }
}
</script>

<style lang="scss" scoped>
.title {
    h2 {
        display: flex;
        justify-content: space-between;
        color: #673bb7;
        margin: 10px 0;
        font-size: 23px;
        border-bottom: 1px solid #673bb7;
        font-weight: bold;
    }
}
.btn {
    display: flex;
    height: 50px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.btn-content {
    margin: 0 auto;
}

.container {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 15px 0;

    .down {
        display: flex;
        align-items: center;

        i.active {
            color: #fff;
        }
    }

    .content {
        display: flex;
        padding: 10px;
        cursor: pointer;

        .left {
            flex: 0.8;

            .gmt {
                font-size: 11px;
                color: #2c3e50;
            }
        }

        .hora {
            flex: 2;

            .hora-local {
                font-size: 11px;
                color: #2c3e50;
            }

            .hora-date {
                color: #2c3e50;
                font-weight: bold;
                font-size: 16px;
            }
        }
    }

    .active {
        background-color: #673ab7;
        border-radius: 5px 5px 0 0;

        .down {
            i {
                color: #fff;
            }
        }

        .left {
            .gmt {
                color: #fff;
            }
        }

        .hora {
            .hora-local {
                color: #fff;
            }

            .hora-date {
                color: #fff;
            }
        }
    }
}

.hora-content {
    display: flex;

    .hora-local {
        flex: 0.8;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .hora-date {
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.open-content {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #ccc;
    padding-top: 10px;

    .pais {
        color: #2c3e50;
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    .city {
        color: #2c3e50;
        font-size: 12px;
        text-align: center;
        font-weight: bold;
    }

    .coord-content {
        display: flex;
        justify-content: space-around;
        margin-bottom: 10px;

        .coord-texts {
            flex: 2;

            .coord-text {
                color: #2c3e50;
                font-size: 14px;
            }
        }

        .coord-btn {
            flex: 1;

            a {
                display: flex;
                width: 80px;
                color: #fff;
                background-color: #673ab7;
                text-decoration: none;
                padding: 10px 15px;
            }
        }
    }
}

.img {
    height: 16px;
    margin: 0 2px 0 0;
}

.search {
    display: block;
    border: 1px solid #ccc;
    width: 100%;
    padding: 5px 10px;
    border-radius: 5px;
}

.content-input {
    position: relative;
    display: flex;

    .limpar {
        position: absolute;
        right: 0;
        top: 0;
        padding: 6px 16px;
        cursor: pointer;
    }
}
</style>

<style lang="scss">
.back {
    background-color: #ffc107;
}

.front {
    background-color: #673ab7;
}

.back,
.front {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 100%;
    height: 100%;
}

.back {
    transform: rotateY(180deg);
}

.back,
.front {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.back,
.flipper,
.front {
    transform-style: preserve-3d;
    border-radius: 5px;
}

*,
:after,
:before {
    border-width: 0;
    border-style: solid;
    border-color: #e2e8f0;
}

button,
input {
    padding: 0;
    line-height: inherit;
    color: inherit;
}

button,
input {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    overflow: visible;
}

.fa,
.fas {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

.fa,
.fas,
.far,
.fal,
.fad,
.fab {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}
</style>
